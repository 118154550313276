function parseCookies(cookieString) {
    let cookies = cookieString.split(';');
    cookies = cookies.map(e => {
        let x = e.split('=');
        if (x.length < 2) {
            return {
                key: '',
                value: '',
            };
        }
        return {
            key: x[0].trim(),
            value: x[1].trim(),
        };
    });
    return cookies;
}

export function storeCookie(data) {
    let dataB64 = btoa(JSON.stringify(data));
    let expiry = new Date();
    expiry.setFullYear(expiry.getFullYear() + 1);
    document.cookie = `mcd-consent=${dataB64};expires=${expiry}`;
}

export function announceUpdate(data, listeners = []) {
    window.dispatchEvent(new CustomEvent('consent-update', { detail: data }));
    listeners.forEach(listener => listener(data));
}

export function loadCookie() {
    let cookies = parseCookies(document.cookie);
    let consentCookie = cookies.find(c => c.key === 'mcd-consent');
    if (consentCookie) {
        let data = atob(consentCookie.value);
        return {
            available: true,
            acceptedServices: JSON.parse(data),
        };
    }
    return {
        available: false,
        acceptedServices: [],
    };
}
