import { announceUpdate, storeCookie } from '../../helpers/CookieHelper';
import scopes from './scopes';

export default function(configuration) {
    let updateListeners = [];
    if (typeof configuration.onUpdate === 'function') {
        updateListeners = [configuration.onUpdate];
    }

    return {
        namespaced: true,
        state: () => ({
            loaded: false,
            show: false,
            titles: configuration.titles,
            description: configuration.description,
            links: configuration.links,
            languageSelector: configuration.languageSelector,
            features: configuration.features,
            updateListeners,
        }),
        mutations: {
            loaded(state) {
                state.loaded = true;
            },
            show(state, value) {
                state.show = Boolean(value);
            },
            addUpdateListener(state, handler) {
                if (typeof handler !== 'function') {
                    throw new Error('handler must be a function');
                }
                state.updateListeners.push(handler);
            },
        },
        actions: {
            save(context) {
                let services = context.getters['scopes/allAcceptedServices'];
                storeCookie(services);
                announceUpdate(services, context.state.updateListeners);
            },
        },
        modules: {
            scopes: scopes(configuration.scopes),
        },
    };
}
