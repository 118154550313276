import Vue from 'vue';
import Vuex from 'vuex';

import CookieConsent from './modules/CookieConsent';
import UserSettingsInitializer from './plugins/UserSettingsInitializer';

Vue.use(Vuex);

export default function(configuration) {
    return new Vuex.Store({
        modules: {
            CookieConsent: CookieConsent(configuration),
        },
        plugins: [UserSettingsInitializer],
    });
}
