<template>
    <div id="app">
        <div class="mcd-dialog-backdrop" v-if="show">
            <OverviewDialog
                :title="overviewTitle"
                :description="description"
                :links="links"
                :show="show && !showDetail"
                @show-details="showDetail = true"
                @update-scope="updateScope"
                @close="close"
            />
            <DetailDialog
                :title="detailTitle"
                :show="show && showDetail"
                @close="showDetail = false"
                @update-scope="updateScope"
                @update-service="updateService"
            />
        </div>
    </div>
</template>

<script>
import OverviewDialog from './components/dialogs/OverviewDialog';
import DetailDialog from './components/dialogs/DetailDialog';
import NamespaceMixin from './components/NamespaceMixin';

export default {
    name: 'CookieConsent',
    components: {
        DetailDialog,
        OverviewDialog,
    },
    mixins: [NamespaceMixin],
    computed: {
        show() {
            return this.$store.state[this.namespace].show;
        },
        overviewTitle() {
            return this.$store.state[this.namespace].titles.overview;
        },
        detailTitle() {
            return this.$store.state[this.namespace].titles.detail;
        },
        description() {
            return this.$store.state[this.namespace].description;
        },
        links() {
            return this.$store.state[this.namespace].links;
        },
    },
    watch: {
        show: function(val) {
            document.body.style.overflowY = val ? 'hidden' : null;
        },
    },
    mounted() {
        if (this.show) {
            document.body.style.overflowY = 'hidden';
        }
    },
    methods: {
        updateScope: function(scopeID, accepted) {
            let scope = this.scopes[scopeID];
            this.scopes[scopeID].checked = accepted;
            this.scopes[scopeID].indeterminate = false;
            for (let id in scope.services) {
                if (Object.prototype.hasOwnProperty.call(scope.services, id)) {
                    this.scopes[scopeID].services[id].checked = accepted;
                }
            }
        },
        updateService: function(scopeID, serviceID, accepted) {
            let scope = this.scopes[scopeID];
            let service = scope.services[serviceID];

            service.checked = accepted;
            let diff = false;
            for (let id in scope.services) {
                if (Object.prototype.hasOwnProperty.call(scope.services, id)) {
                    if (scope.services[id].checked !== accepted) {
                        diff = true;
                    }
                }
            }

            if (!diff) {
                scope.checked = accepted;
            }
        },
        close: function() {
            this.$store.commit(`${this.namespace}/show`, false);
        },
    },
    data: () => ({
        showDetail: false,
    }),
};
</script>
