export default {
    CookieDialog: {
        actions: {
            acceptAll: 'Accepter tout',
            rejectAll: 'Refuser',
            save: 'Enregistrer les paramètres',
            close: 'Fermer',
            showDetails: "Plus·d'informations",
        },
        cookieTable: {
            headline: 'Cookies',
            fields: {
                name: 'Nom',
                duration: 'Durée de vie',
                description: 'Description',
            },
        },
    },
};
