<template>
    <MultiCheckbox
        :label="scope.label"
        :checked="checked"
        :indeterminate="indeterminate"
        :disabled="required"
        :no-label="noLabel"
        @change="updateScope($event.target.checked)"
    />
</template>

<script>
import MultiCheckbox from './MultiCheckbox';
import NamespaceMixin from './NamespaceMixin';

export default {
    name: 'ScopeCheckbox',
    components: { MultiCheckbox },
    mixins: [NamespaceMixin],
    props: {
        scopeId: {
            type: String,
            required: true,
        },
        noLabel: Boolean,
    },
    computed: {
        scope() {
            return this.$store.getters[`${this.namespace}/scopes/getScope`](
                this.scopeId,
            );
        },
        indeterminate() {
            return this.$store.getters[
                `${this.namespace}/scopes/isIndeterminate`
            ](this.scopeId);
        },
        checked() {
            return this.required
                ? true
                : this.$store.getters[`${this.namespace}/scopes/isAccepted`](
                      this.scopeId,
                  );
        },
        required() {
            return this.$store.getters[`${this.namespace}/scopes/isRequired`](
                this.scopeId,
            );
        },
    },
    methods: {
        updateScope(granted) {
            this.$store.commit(`${this.namespace}/scopes/updateScope`, {
                scopeID: this.scopeId,
                granted,
            });
        },
    },
};
</script>
