import { announceUpdate, loadCookie } from '../../helpers/CookieHelper';

export default store => {
    let { available, acceptedServices } = loadCookie();
    const namespace = store.state.cookieConsentNamespace || 'CookieConsent';
    acceptedServices.forEach(item => {
        let [scope, service] = item.split('/');
        store
            .dispatch(`${namespace}/scopes/updateConsent`, {
                scopeID: scope,
                serviceID: service,
                granted: true,
            })
            .then();
    });
    store.commit(`${namespace}/loaded`);
    store.commit(`${namespace}/show`, !available);
    announceUpdate(
        store.getters[`${namespace}/scopes/allAcceptedServices`],
        store.state.updateListeners,
    );
};
