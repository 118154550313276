<template>
    <transition name="fade">
        <div class="mcd-dialog" v-if="show">
            <div class="mcd-dialog-header">
                <h1>{{ title }}</h1>
            </div>

            <div class="mcd-dialog-content mcd-flex mcd-no-scroll">
                <div class="mcd-dialog-content-sidebar mcd-scroll">
                    <ScopeList />
                </div>
                <div class="mcd-dialog-content-panel mcd-scroll">
                    <DetailedScopeList />
                </div>
            </div>

            <div class="mcd-dialog-footer" style="justify-content: center">
                <button
                    class="mcd-button mcd-button-light"
                    @click.prevent="$emit('close')"
                >
                    {{ $t('CookieDialog.actions.close') }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import ScopeList from '../ScopeList';
import DetailedScopeList from '../DetailedScopeList';

export default {
    name: 'DetailDialog',
    components: { DetailedScopeList, ScopeList },
    props: {
        title: String,
        show: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
