<template>
    <div class="mcd-table-responsive">
        <table>
            <thead>
                <tr>
                    <th>{{ $t('CookieDialog.cookieTable.fields.name') }}</th>
                    <th>
                        {{ $t('CookieDialog.cookieTable.fields.duration') }}
                    </th>
                    <th>
                        {{ $t('CookieDialog.cookieTable.fields.description') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="cookie in cookies" :key="cookie.name">
                    <td>{{ cookie.name }}</td>
                    <td>{{ cookie.duration }}</td>
                    <td>{{ cookie.description }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'CookieTable',
    components: {},
    props: {
        cookies: {
            type: Array,
            required: true,
        },
    },
};
</script>
