<template>
    <div class="mcd-scope-list">
        <div
            v-for="(scope, scopeID) in scopes"
            :key="scopeID"
            class="mcd-scope-list-entry"
        >
            <ScopeCheckbox
                :scope-id="scopeID"
                class="mcd-scope-list-entry-checkbox"
            />
            <div class="mcd-scope-list-entry-services">
                <MultiCheckbox
                    v-for="(service, id) in scope.services"
                    :key="id"
                    :label="service.label"
                    :checked="service.required ? true : service.accepted"
                    :disabled="service.required"
                    @change="updateService(scopeID, id, $event.target.checked)"
                    class="mcd-scope-list-entry-services-entry"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MultiCheckbox from './MultiCheckbox';
import ScopeCheckbox from './ScopeCheckbox';
import NamespaceMixin from './NamespaceMixin';

export default {
    name: 'ScopeList',
    components: { ScopeCheckbox, MultiCheckbox },
    mixins: [NamespaceMixin],
    computed: {
        scopes() {
            return this.$store.state[this.namespace].scopes;
        },
    },
    methods: {
        updateService(scopeID, serviceID, granted) {
            this.$store.commit(`${this.namespace}/scopes/updateConsent`, {
                scopeID,
                serviceID,
                granted,
            });
        },
    },
};
</script>
