import de from './locales/de';
import en from './locales/en';
import fr from './locales/fr';

export default () => ({
    titles: {
        overview: 'Wir nutzen Cookies.',
        detail: 'Liste verwendeter Cookies',
    },
    description:
        'Auch wir verwenden Cookies, damit bei Ihrem Besuch unsere Webseite technisch funktioniert (notwendig, immer aktiv) und für Statistiken zur Nutzung unserer Webseite (optional) sowie zur Personalisierung der Inhalte (optional). Durch Klicken auf "Alles Akzeptieren" stimmen Sie dem Einsatz von allen optionalen Cookies zu, durch Klicken auf „Einstellungen speichern" können Sie Ihre Einwilligung mit den gewählten Einstellungen geben oder Einwilligungen für die Zukunft anpassen. Nähere Informationen, insbesondere zu Einstellungs- und Widerspruchsmöglichkeiten, erhalten Sie in unserer Datenschutzerklärung.',
    defaultLocale: 'de',
    locales: {
        de,
        en,
        fr,
    },
    languageSelector: false,
    features: {
        rejectAllButton: false,
    },
});
