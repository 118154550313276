<template>
    <div>
        <div
            v-for="(scope, id) in scopes"
            :key="id"
            class="mcd-detail-list-item"
        >
            <div class="mcd-detail-list-item-title">
                <h2>{{ scope.label }}</h2>
                <ScopeCheckbox
                    :scope-id="id"
                    class="mcd-float-right"
                    no-label
                />
            </div>
            <ServiceDisplay
                v-for="(service, sid) in scope.services"
                :key="sid"
                :id="sid"
                :scope-id="id"
                :service="service"
                class="mcd-detail-list-item-service"
            />
        </div>
    </div>
</template>

<script>
import ServiceDisplay from './ServiceDisplay';
import ScopeCheckbox from './ScopeCheckbox';
import NamespaceMixin from './NamespaceMixin';

export default {
    name: 'DetailedScopeList',
    components: { ScopeCheckbox, ServiceDisplay },
    mixins: [NamespaceMixin],
    computed: {
        scopes() {
            return this.$store.state[this.namespace].scopes;
        },
    },
};
</script>
