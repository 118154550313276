export default {
    CookieDialog: {
        actions: {
            acceptAll: 'Accept all',
            rejectAll: 'Reject',
            save: 'Save settings',
            close: 'Close',
            showDetails: 'Show details',
        },
        cookieTable: {
            headline: 'Cookies',
            fields: {
                name: 'Name',
                duration: 'Duration',
                description: 'Description',
            },
        },
    },
};
