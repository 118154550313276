<template>
    <select v-model="locale">
        <option
            v-for="option in languageOptions"
            :key="option.value"
            :value="option.value"
        >
            {{ option.label }}
        </option>
    </select>
</template>

<script>
export default {
    name: 'LanguageSelector',
    computed: {
        locale: {
            get() {
                return this.$root.$i18n.locale;
            },
            set(locale) {
                this.$setLanguage(locale);
            },
        },
        languageOptions() {
            const locales = Object.keys(this.$root.$i18n.messages);
            try {
                const displayNames = new Intl.DisplayNames([this.locale], {
                    type: 'language',
                });
                return locales.map(locale => ({
                    value: locale,
                    label: displayNames.of(locale),
                }));
            } catch (e) {
                // fallback, when Browser does not support Intl.DisplayNames
                return locales.map(locale => ({
                    value: locale,
                    label: locale,
                }));
            }
        },
    },
};
</script>
