const getters = {
    getIdentifiers: state => {
        return Object.keys(state);
    },
    getScope: state => id => {
        return state[id];
    },
    getService: state => (scopeID, id) => {
        if (id) {
            return state[scopeID].services[id];
        } else {
            return Object.values(state[scopeID].services)[0];
        }
    },
    allAcceptedServices: state => {
        let allServices = [];
        for (let [scopeID, scope] of Object.entries(state)) {
            let services = Object.entries(scope.services).filter(
                entry => entry[1].accepted,
            );
            let mappedServices = services.map(([id]) => `${scopeID}/${id}`);
            allServices = allServices.concat(mappedServices);
        }
        return allServices;
    },
    isIndeterminate: state => scopeID => {
        let scope = state[scopeID];
        let services = Object.values(scope.services);
        let accepted = services[0].accepted;
        return Boolean(services.find(s => s.accepted !== accepted));
    },
    isAccepted: (state, getters) => scopeID => {
        return getters.isIndeterminate(scopeID)
            ? false
            : getters.getService(scopeID).accepted;
    },
    isRequired: state => scopeID => {
        let scope = state[scopeID];
        let services = Object.values(scope.services);
        for (let service of services) {
            if (!service.required) {
                return false;
            }
        }
        return true;
    },
};

const mutations = {
    updateConsent(state, { scopeID, serviceID, granted }) {
        if (!state[scopeID]) {
            throw new Error(`Scope not known: ${scopeID}`);
        }
        let service = state[scopeID].services[serviceID];
        if (service) {
            service.accepted = service.required ? true : granted;
        } else {
            throw new Error(`Service not known: ${scopeID}/${serviceID}`);
        }
    },
    updateScope(state, { scopeID, granted }) {
        let services = state[scopeID].services;
        Object.values(services).forEach(s => {
            s.accepted = s.required ? true : granted;
        });
    },
    acceptAll(state) {
        let services = [];
        Object.values(state).forEach(scope => {
            services.push(...Object.values(scope.services));
        });
        services.forEach(s => (s.accepted = true));
    },
    acceptRequired(state) {
        Object.values(state).forEach(scope => {
            Object.values(scope.services).forEach(service => {
                service.accepted = service.required || false;
            });
        });
    },
};

const actions = {
    updateConsent({ state, commit }, { scopeID, serviceID, granted }) {
        if (
            state[scopeID] &&
            state[scopeID].services &&
            state[scopeID].services[serviceID]
        ) {
            commit('updateConsent', { scopeID, serviceID, granted });
            return true;
        }
        return false;
    },
};

export default function(scopes) {
    Object.values(scopes).forEach(scope => {
        Object.values(scope.services).forEach(service => {
            service.accepted = Boolean(service.required);
        });
    });

    return {
        namespaced: true,
        state: () => JSON.parse(JSON.stringify(scopes)),
        getters,
        mutations,
        actions,
    };
}
