<script>
export default {
    name: 'NamespaceMixin',
    computed: {
        namespace() {
            let ns = this.$store.state.cookieConsentNamespace;
            if (!ns) {
                ns = 'CookieConsent';
            }
            return ns;
        },
    },
};
</script>
