export default {
    CookieDialog: {
        actions: {
            acceptAll: 'Alles akzeptieren',
            rejectAll: 'Ablehnen',
            save: 'Einstellungen speichern',
            close: 'Schließen',
            showDetails: 'Mehr Informationen',
        },
        cookieTable: {
            headline: 'Cookies',
            fields: {
                name: 'Name',
                duration: 'Lebensdauer',
                description: 'Beschreibung',
            },
        },
    },
};
