<template>
    <transition name="fade">
        <div class="mcd-dialog" id="mcd-summary" v-if="show">
            <div class="mcd-dialog-header">
                <h1>{{ title }}</h1>
            </div>

            <div class="mcd-dialog-content mcd-scroll mcd-flex-col">
                <p>{{ description }}</p>

                <div class="mcd-dialog-content-scopes">
                    <span
                        v-for="id in scopeIDs"
                        :key="id"
                        class="mcd-dialog-content-scopes-item"
                    >
                        <ScopeCheckbox :scope-id="id" />
                        <i
                            class="mcd-icon-question mcd-clickable"
                            @click="$emit('show-details')"
                        />
                    </span>
                </div>

                <div class="mcd-dialog-content-actions">
                    <button
                        id="mcd-action-accept-all"
                        class="mcd-button mcd-button-dark"
                        @click="acceptAll"
                    >
                        {{ $t('CookieDialog.actions.acceptAll') }}
                    </button>
                    <button
                        v-if="showRejectButton"
                        id="mcd-action-reject-all"
                        class="mcd-button mcd-button-dark"
                        @click="rejectAll"
                    >
                        {{ $t('CookieDialog.actions.rejectAll') }}
                    </button>
                    <button
                        id="mcd-action-save"
                        class="mcd-button mcd-button-dark"
                        @click="submit"
                    >
                        {{ $t('CookieDialog.actions.save') }}
                    </button>
                </div>
            </div>

            <div class="mcd-dialog-footer">
                <div class="mcd-dialog-footer-left">
                    <a
                        v-for="link in links"
                        :target="link.target"
                        :key="link.href"
                        :href="link.href"
                        class="mcd-dialog-footer-link"
                        >{{ link.text }}</a
                    >
                    <a
                        href="#"
                        @click.prevent="$emit('show-details')"
                        class="mcd-dialog-footer-link"
                        >{{ $t('CookieDialog.actions.showDetails') }}</a
                    >
                </div>
                <div class="mcd-dialog-footer-right">
                    <language-selector v-if="languageSelector" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ScopeCheckbox from '../ScopeCheckbox';
import LanguageSelector from '../LanguageSelector';
import NamespaceMixin from '../NamespaceMixin';

export default {
    name: 'OverviewDialog',
    components: { LanguageSelector, ScopeCheckbox },
    mixins: [NamespaceMixin],
    props: {
        title: String,
        description: String,
        links: Array,
        show: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        scopeIDs() {
            return this.$store.getters[
                `${this.namespace}/scopes/getIdentifiers`
            ];
        },
        languageSelector() {
            return this.$store.state[this.namespace].languageSelector;
        },
        showRejectButton() {
            return (
                this.$store.state[this.namespace].features.rejectAllButton ||
                false
            );
        },
    },
    methods: {
        submit() {
            this.$store.dispatch(`${this.namespace}/save`).then(() => {
                this.$emit('close');
            });
        },
        acceptAll() {
            this.$store.commit(`${this.namespace}/scopes/acceptAll`);
            this.submit();
        },
        rejectAll() {
            this.$store.commit(`${this.namespace}/scopes/acceptRequired`);
            this.submit();
        },
    },
};
</script>
