<template>
    <input
        v-if="noLabel"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        v-indeterminate="indeterminate"
        v-on="$listeners"
        class="mcd-checkbox"
    />
    <label v-else class="mcd-checkbox">
        <input
            type="checkbox"
            :checked="checked"
            :disabled="disabled"
            v-indeterminate="indeterminate"
            v-on="$listeners"
        />
        {{ label }}
    </label>
</template>

<script>
export default {
    name: 'MultiCheckbox',
    props: {
        label: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        indeterminate: Boolean,
        noLabel: Boolean,
    },
    directives: {
        indeterminate: function(el, binding) {
            el.indeterminate = Boolean(binding.value);
        },
    },
};
</script>
