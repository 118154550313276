<template>
    <div>
        <div class="mcd-detail-list-item-service-title">
            <h3>{{ service.label }}</h3>
            <MultiCheckbox
                label=""
                no-label
                :checked="service.required ? true : service.accepted"
                :disabled="service.required"
                @change="updateService(scopeId, id, $event.target.checked)"
            />
        </div>
        <div
            v-for="(info, i) in service.information"
            :key="i"
            class="mcd-detail-list-item-service-info"
        >
            <h4>{{ info.title }}</h4>
            <p>{{ info.description }}</p>
        </div>
        <h4>{{ $t('CookieDialog.cookieTable.headline') }}</h4>
        <CookieTable v-if="mdAndUp" :cookies="service.cookies" />
        <CookieList v-else :cookies="service.cookies" />
    </div>
</template>

<script>
import CookieTable from './CookieTable';
import CookieList from './CookieList';
import MultiCheckbox from './MultiCheckbox';
import NamespaceMixin from './NamespaceMixin';

export default {
    name: 'ServiceDisplay',
    components: {
        MultiCheckbox,
        CookieTable,
        CookieList,
    },
    mixins: [NamespaceMixin],
    props: {
        service: {
            type: Object,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        scopeId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        windowWidth: window.innerWidth,
    }),
    computed: {
        mdAndUp() {
            return this.windowWidth >= 768;
        },
    },
    methods: {
        updateService(scopeID, serviceID, granted) {
            this.$store.commit(`${this.namespace}/scopes/updateConsent`, {
                scopeID,
                serviceID,
                granted,
            });
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
};
</script>
