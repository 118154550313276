<template>
    <div class="mcd-list-responsive">
        <div v-for="cookie in cookies" :key="cookie.name" class="mcd-card">
            <div class="mcd-card-title">{{ cookie.name }}</div>
            <div class="mcd-card-subtitle">
                <span class="mcd-text-bold"
                    >{{ $t('CookieDialog.cookieTable.fields.duration') }}:</span
                >
                {{ cookie.duration }}
            </div>
            <p class="mcd-card-text">{{ cookie.description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookieTable',
    components: {},
    props: {
        cookies: {
            type: Array,
            required: true,
        },
    },
};
</script>
