import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './store';
import CookieConsent from './CookieConsent.vue';
import { merge } from 'lodash';
import defaultConfiguration from './defaultConfiguration';
import LanguageSwitcher from './plugins/LanguageSwitcher';

require('./styles/index.scss');

Vue.use(VueI18n);
Vue.use(LanguageSwitcher);

const initialize = (mount, config) => {
    config = merge(defaultConfiguration(), config);

    let instance = new Vue({
        store: store(config),
        render: h => h(CookieConsent),
        i18n: new VueI18n({
            locale: config.defaultLocale,
            messages: config.locales,
        }),
    }).$mount(mount);
    if (window.CookieConsent) {
        window.CookieConsent.vue = instance;
    }
    return instance;
};

export default initialize;
window.CookieConsent = {
    initialize,
    show: function() {
        if (this.vue) {
            const ns =
                this.vue.$store.state.cookieConsentNamespace || 'CookieConsent';
            this.vue.$store.commit(`${ns}/show`, true);
        }
    },
    setService: function(slug, isAccepted) {
        if (this.vue) {
            try {
                let [scope, service] = slug.split('/');
                const ns =
                    this.vue.$store.state.cookieConsentNamespace ||
                    'CookieConsent';
                this.vue.$store.commit(`${ns}/scopes/updateConsent`, {
                    scopeID: scope,
                    serviceID: service,
                    granted: isAccepted,
                });
                this.vue.$store.dispatch(`${ns}/save`).then();
            } catch (ex) {
                console.error(ex);
            }
        }
    },
    setLanguage: function(language) {
        if (this.vue) {
            this.vue.$setLanguage(language);
        } else {
            throw new Error('application is not initialized');
        }
    },
    addUpdateListener: function(handler) {
        if (this.vue) {
            const ns =
                this.vue.$store.state.cookieConsentNamespace || 'CookieConsent';
            this.vue.$store.commit(`${ns}/addUpdateListener`, handler);
        } else {
            throw new Error('application is not initialized');
        }
    },
};
